<template>
    <modal ref="modalResponder" :titulo="titulo" tamano="modal-lg" adicional="Enviar" :cargando="loading" :desactivar-guardar="false" :no-aceptar="true" @adicional="guardar">
        <ValidationObserver ref="validacion">
            <div class="row mx-0">
                <div class="col">
                    <ValidationProvider v-slot="{errors}" rules="required|max:1200" name="mensaje">
                        <label class="pl-3 text-gris f-15 my-3"> Mensaje </label>
                        <el-input v-model="model.mensaje" placeholder="Texto.." type="textarea" :rows="5" maxlength="1201" class="w-100 area-radius mb-3" />
                        <span class="text-danger w-100 f-12"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Foros from '~/services/foros/foros'

export default {
    data(){
        return {
            id_foro: parseInt(this.$route.params.id_foro),
            loading: false,
            model:{
                id: null,
                mensaje: null,
                id_padre: null,
                id_foro: null
            },
            titulo: ''
        }
    },
    methods: {
        toggle(idPadre){
            this.limpiarForm()
            this.titulo = idPadre? 'Responder': 'Preguntar en el Foro'
            this.model.id_foro = this.id_foro
            this.model.id_padre = idPadre
            this.$refs.modalResponder.toggle();
        },
        limpiarForm(){
            this.model = {
                id: null,
                mensaje: null,
                id_padre: null,
                id_foro: null
            }
            this.$refs.validacion.reset()
        },
        async guardar(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.loading = true
                const {data: {data} }= await Foros.crearMensajeForo(this.model)
                this.loading = false
                this.$emit('actualizar', data.mensaje)
                this.notificacion('Mensaje', 'mensaje creado', 'success')
                this.$refs.modalResponder.toggle();

            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>